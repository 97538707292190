
import { defineComponent, ref, Ref } from 'vue';
import paymentInfo,
{ manageInfo as paymentManageInfo } from '@/components/view/common/payment/index';
import { order, orderType } from '@/data';

export default defineComponent({
    components: {
        paymentInfo,
        paymentManageInfo
    },
    props: {
        id: {
            type: String,
            required: true
        },
        ProjectType: {
            type: String
        }
    },
    setup(props) {
        const orderDetail: Ref<orderType.OrderInfo | {}> = ref({});
        const showManageInfo = ref(false);
        console.log('props.ProjectType', props.ProjectType);
        const api = props.ProjectType === 'community' ? order.getInfoCommunity : order.getInfoOffice;
        api(props.id, (data: orderType.OrderInfo) => {
            orderDetail.value = data;
            showManageInfo.value = true;
        });

        return {
            orderDetail,
            showManageInfo
        };
    }
});
